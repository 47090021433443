<!--
    props: options: array => Title, Type, Url
    emits: update-typeahead, update-search
-->
<template>
    <div :class="typeaheadState">
        <div class="typeahead__toggle" ref="toggle" @mousedown="toggle">
            <input type="text" class="typeahead__search" ref="search"
                v-model="search"
                @focus="onFocus"
                @blur="onBlur"
                @keydown.esc="onEscapeKey"
                @keydown.down="onDownKey"
                @keydown.up="onUpKey"
                @keydown.enter="onEnterKey"
                @input="onInput">

            <span class="typeahead__text" ref="text" style="visibility:hidden;">{{displayText}}</span>
            <button class="typeahead__button desktop-only" @click="onSearch" type="button" ref="btn">Search</button>
            <button class="btn-search fas fa-search mobile-only" type="submit"></button>
        </div>
        <div class="list-wrap" ref="list" v-if="(open && options.length > 0)">
            <span class="list-heading">Search suggestions</span>
            <ul class="typeahead__list">
                <li class="typeahead__item" v-for="(option,index) in options" :key="index">
                    <a class="typeahead__link" @mousedown.prevent="select(option)" :class="[selectIndex === index ? 'typeahead__active' : '']">
                        <span v-html="copyWithHighlights(option.Title)"></span>
                        <span class="spacer" v-if="option.Type">|</span>
                        <span class="type" v-if="option.Type">{{option.Type}}</span>
                    </a>
                </li>
                <li>
                    <a class="typeahead__link all-results" @mousedown.prevent="selectAll()">
                        See All Results for "{{currentSearchTerm}}"
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        options: {
            type: Array,
            default() {
                return []
            }
        },
        currentSearchTerm: ''
    },
    data(){
        return {
            open: false,
            selectIndex: -1,
            displayText: '',
            search: ''
        }
    },
    computed: {
        typeaheadState() {
            return this.open ? 'typeahead typeahead__open' : 'typeahead';
        }
    },
    mounted: function () {
    },
    created: function() {
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        if (params.get('Query') !== null && params.get('Query') !== ''){
            this.search = params.get('Query');
        } else if (params.get('query') !== null && params.get('query') !== ''){
            this.search = params.get('query');
        }
    },
    methods: {
        onInput() {
            this.selectIndex = -1;
            if(this.search.length > 2){
                this.$emit('update-typeahead', this.search);
            }else{
                this.$emit('update-typeahead', '');
            }
        },
        onDownKey() {
            if(this.options.length -1 > this.selectIndex){
                this.selectIndex++;
            }
        },
        onUpKey() {
            if(this.selectIndex > -1){
                this.selectIndex--;
            }
        },
        onEnterKey() {
            const option = this.options[this.selectIndex];

            if(option){
                this.select(option);
            }

            this.$emit('update-search', this.search);
            this.displayText = this.search;
            this.open = false;
        },
        onSearch() {
            this.displayText = this.search;
            this.$emit('update-search', this.search);
        },
        selectAll(){
            this.displayText = this.search;
            this.$emit('update-search', this.search);
            this.$refs.search.blur();
        },
        select(option) {
            this.search = option.Title;
            this.displayText = this.search;
            this.$emit('update-search', this.search);
            this.$refs.search.blur();
        },
        toggle(e) {
            if(e.target === this.$refs.toggle ||
                e.target === this.$refs.search ||
                e.target === this.$refs.text) {

                if(this.open) {
                    if(e.target !== this.$refs.search &&
                        e.target !== this.$refs.text){

                        this.$refs.search.blur();
                    }
                }else{
                    this.$refs.search.focus();
                }
            }
        },
        onFocus() {
            this.open = true;
        },
        onBlur() {
            this.displayText = this.search;
            this.selectIndex = -1;
            this.open = false;
        },
        onEscapeKey() {
            this.$refs.search.blur();
        },
        copyWithHighlights(copy) {
            const term = this.search; // search query we want to highlight in results 
            const newCopy = copy.replace(new RegExp(term, "gi"), (match) => `<mark>${match}</mark>`); 
            return newCopy;
        },
    }
}
</script>
