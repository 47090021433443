<template>
    <div id="header-typeahead">
        <typeahead :options="options" :current-search-term="searchQuery" @update-search="searchNew" @update-typeahead="getTypeaheadItems"></typeahead>
    </div>
</template>

<script>
import {debounce} from 'lodash';
import Typeahead from '~/src/custom/vue/components/ui/typeahead';

export default {
    name: 'HeaderTypeahead',
    data: function () {
			return {
                searchQuery: '',
                searchedQuery: '',
                isDropdownOpen: false,
                typeAheadItems: [],
                options: []
			}
		},
    components: {
        typeahead: Typeahead
    },

    methods: {
        // closeTypeahead() {
        //     console.log('closing typeahead');
        //     this.$refs.Typeahead.onBlur();
        // },
        copyWithHighlights(copy) {
            // if(copy == ''){
            //     copy = 'no summary available : ...';
            // }
            const term = this.searchedQuery; // search query we want to highlight in results 
            const newCopy = copy.replace(new RegExp(term, "gi"), (match) => `<mark>${match}</mark>`); 

            return newCopy;
        },
        setQuery(item) {
            console.log('set query');
            this.searchQuery = item.Title;
            this.isDropdownOpen = false;
            this.searchNew();
        },
        readyNewSearch() {
            if(this.searchQuery.length > 2){
                this.typeAheadSearch();
            }
        },
        searchNew(query) {
            this.searchedQuery = query;
            let str = `/search?query=${this.searchedQuery}&PageIndex=0&PageSize=10`;
            window.location.href = str;
        },
        getTypeaheadItems(query) {
            console.log('get type ahead items. query: ', query);

            if(query === ''){
                this.options = [];
                return;
            }

            this.searchQuery = query;
            this.typeAheadSearch();

        },
        typeAheadSearch: debounce(function(){
            fetch(`/api/paslodesearch?Query=${this.searchQuery}&PageIndex=0&PageSize=7`)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    this.options = data.Items;
                })
                    .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                    this.options = [];
                });
        }, 500),
    }
}

</script>

<style>

#header-typeahead{
    padding: 0;
}
#header-typeahead .mobile-only{
    display: none;
}
#header-typeahead .desktop-only{
    display: block;
}
@media (max-width: 991px) { 
    #header-typeahead .mobile-only{
        display: block;
    }
    #header-typeahead .desktop-only{
        display: none;
    }
}
.nav-search-mobile .search-mobile #header-typeahead .typeahead .btn-search{
    z-index: 2;
    padding: 0;
    height: 50px;
    width: 50px;
    position: absolute;
    right: 0;
    top: 1px;
}
.nav-search-mobile .search-mobile #header-typeahead .typeahead .fa-search:before{
    font-size: 38px;
    -webkit-text-stroke: 1px #ff4d00;
}
.nav-search-mobile .search-mobile #header-typeahead .typeahead .list-wrap{
    max-width: unset;
    width: 100vw;
    left: -15px;
    padding: 10px 20px 40px 20px;
    top: 72px;
}
.nav-search-mobile .search-mobile #header-typeahead .typeahead .typeahead__toggle input{
    font-size: 20px;
    height: 52px;
}
.close-typeahead{
    width: 14px;
    position: absolute;
    right: 0;
    right: 31px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.close-typeahead img{
    height: 14px;
    min-width: 14px;
}
.nav-wrapper .nav-container .typeahead {
	 text-align: left;
	 margin: auto;
	 border-radius: 0;
	 position: relative;
	 z-index: 1;
	 width: 100%;
	 font-size: 20px;
}
 .nav-wrapper .nav-container .typeahead__open.typeahead__text {
	 color: #999;
	 opacity: 0;
}
 .nav-wrapper .nav-container .typeahead__toggle {
	 position: relative;
	 z-index: 1;
	 width: 100%;
     display: flex;
     justify-content: end;
}
 .nav-wrapper .nav-container .typeahead__toggle input {
	 height: 52px;
}
.nav-wrapper .nav-container .typeahead__search {
	 position: absolute;
	 top: 0;
	 left: 0;
	 line-height: 16px;
	 font-size: 16px;
	 padding: 10px;
	 width: 100%;
	 display: block;
	 cursor: text;
	 border: 1px solid #70787f !important;
    background: #FFFFFF;
	 border: none;
	 outline: none;
	 z-index: 2;
}
 .nav-wrapper .nav-container .typeahead__text {
	 visibility: hidden;
	 min-height: 52px;
	 font-size: 16px;
	 line-height: 36px;
	 padding: 10px;
	 display: inline-block;
	 position: relative;
	 z-index: 3;
	 top: -2px;
	 left: 7px;
}
 .nav-wrapper .nav-container .typeahead .list-wrap {
	 /* margin-top: -5px; */
	 background: white;
     position: absolute;
     width: 600px;
     left: -25px;
     top: 83px;
     padding: 0 20px 30px 25px;
}
 .nav-wrapper .nav-container .typeahead .list-wrap .list-heading {
	 padding: 10px;
	 color: #6a6a69;
	 font-size: 13px;
}
 .nav-wrapper .nav-container .typeahead__list {
	 background: white;
	 padding: 0;
	 margin: 0;
}
 .nav-wrapper .nav-container .typeahead__item {
	 display: block;
	 /* border-top: solid 1px #f4f4f4; */
}
 .nav-wrapper .nav-container .typeahead__link {
	 text-decoration: none;
	 color: #1d1d1b;
	 display: block;
	 padding: 10px;
	 line-height: 16px;
	 font-size: 16px;
	 cursor: pointer;
     font-weight: normal;
}
 .nav-wrapper .nav-container .typeahead__link mark{
     font-weight: bold;
    background: none;
 }
 .nav-wrapper .nav-container .typeahead__link:hover {
	 color: #1d1d1b;
	 text-decoration: none;
}
 .nav-wrapper .nav-container .typeahead__link .type {
	 color: #6a6a69;
	 font-weight: 100;
}
 .nav-wrapper .nav-container .typeahead__link.all-results {
	 position: relative;
	 text-decoration: underline;
	 display: inline-block;
     padding-bottom: 0;
     font-size: 18px;
     font-weight: bold;
}
 .nav-wrapper .nav-container .typeahead__link.all-results:after {
	 content: '\00BB';
	 position: absolute;
	 top: 8px;
	 right: -10px;
	 font-size: 20px;
}
 .nav-wrapper .nav-container .typeahead__active {
	 background: #f5f5f5;
	 color: black;
}
 .nav-wrapper .nav-container .typeahead__active.typeahead__link:hover {
	 color: black;
}
 .nav-wrapper .nav-container .typeahead__button {
	 position: absolute;
	 top: 0;
	 right: 0;
	 height: 52px;
	 color: white;
	 background: #98000e;
	 text-transform: uppercase;
	 font-size: 18px;
	 font-weight: bold;
	 border-radius: 0;
	 padding: 15px 18px;
	 z-index: 4;
}
 
</style>
