
import '~/src/custom/scss/components/typeahead/typeahead.scss';

// Vue JS
// import Vue App
import Vue from 'vue';
import HeaderTypeahead from '~/src/custom/vue/containers/header-typeahead';

new Vue({
    render: h => h(HeaderTypeahead),
}).$mount('#apptypeahead-search');

new Vue({
    render: h => h(HeaderTypeahead),
}).$mount('#apptypeahead-search-mobile');
